import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

export default function Cookies() {
  const seo = {
    title: 'Cookies - Przychodnia specjalistyczna Medicus-Dukla',
    description: 'Polityka prywatności i Cookies - Przychodnia specjalistyczna Medicus-Dukla',
    url: 'https://medicusdukla.pl/cookies'
  }
  return (
    <Layout pageClass={'sample-page'} bg={null}>
      <SEO
        title={ seo.title}
        description={ seo.description }
        url={ seo.url }
      />
      <div id="main" className="apteki">
        <p>Właśnie pracujemy nad kontentem strony</p>
      </div>
    </Layout>
  )
}
